import React from "react";
import {
  Container,
  Typography,
  MenuItem,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import DurationPicker from "./DurationPicker"; // Adjust the import path if needed

const muscles = ["Chest", "Back", "Shoulders", "Legs", "Arms", "Abs"];
const exercises = [
  "Bench Press",
  "Squats",
  "Deadlifts",
  "Pull-Ups",
  "Push-Ups",
  "Leg Press",
  "Cable Crossovers",
  "Dumbbell Shoulder Press",
];
const avoidExercises = ["Romanian Deadlifts", "Lunges", "Overhead Press"];

const CustomContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper || "#f4f6f8",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  // boxShadow: theme.shadows[3],
}));

const CustomButton = styled(Button)(({ theme }) => ({
  fontFamily: '"Roboto Slab", serif',
  fontWeight: 700,
  padding: theme.spacing(1.5, 4),
  textTransform: "none",
  fontSize: "1rem",
  boxShadow: theme.shadows[3],
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark || "#003d6a",
  },
}));

const WorkoutSelector = ({ formData, handleChange, handleFetchData }) => {
  return (
    <CustomContainer>
      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: '"Roboto Slab", serif', fontWeight: 500, mb: 1 }}
        >
          How long do you want to work out for?
        </Typography>
        <DurationPicker
          duration={formData.duration}
          handleDurationChange={handleChange("duration")}
        />
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: '"Roboto Slab", serif', fontWeight: 500, mb: 1 }}
        >
          What muscles do you want to target?
        </Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Muscles</InputLabel>
          <Select
            multiple
            value={formData.selectedMuscleGroups}
            onChange={(e) =>
              handleChange("selectedMuscleGroups")(e.target.value)
            }
            renderValue={(selected) => selected.join(", ")}
          >
            {muscles.map((muscle) => (
              <MenuItem key={muscle} value={muscle}>
                <Checkbox
                  checked={formData.selectedMuscleGroups.includes(muscle)}
                />
                <ListItemText primary={muscle} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: '"Roboto Slab", serif', fontWeight: 500, mb: 1 }}
        >
          Are there any specific exercises you want to do?
        </Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Exercises</InputLabel>
          <Select
            multiple
            value={formData.preferredExercises}
            onChange={(e) => handleChange("preferredExercises")(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {exercises.map((exercise) => (
              <MenuItem key={exercise} value={exercise}>
                <Checkbox
                  checked={formData.preferredExercises.includes(exercise)}
                />
                <ListItemText primary={exercise} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: '"Roboto Slab", serif', fontWeight: 500, mb: 1 }}
        >
          Anything we need to avoid today?
        </Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Avoided Exercises</InputLabel>
          <Select
            multiple
            value={formData.avoidedExercises}
            onChange={(e) => handleChange("avoidedExercises")(e.target.value)}
            renderValue={(selected) => selected.join(", ")}
          >
            {avoidExercises.map((exercise) => (
              <MenuItem key={exercise} value={exercise}>
                <Checkbox
                  checked={formData.avoidedExercises.includes(exercise)}
                />
                <ListItemText primary={exercise} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleFetchData}
        >
          Generate Workout Plan
        </CustomButton>
      </Box>
    </CustomContainer>
  );
};

export default WorkoutSelector;

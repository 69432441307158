import createPrompt from "./prompt";

const getEndpoint = () => {
  console.log(process.env.NODE_ENV);
  return process.env.NODE_ENV === "production"
    ? "https://gym-gpt.onrender.com"
    : "http://localhost:8080";
};

const callGPT = async ({
  weight,
  goals,
  availableEquipment,
  selectedMuscleGroups,
  duration,
}) => {
  const prompt = createPrompt({
    weight,
    goals,
    availableEquipment,
    selectedMuscleGroups,
    duration,
  });
  try {
    const api = `${getEndpoint()}/gpt`;
    console.log(api);
    const response = await fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: prompt }),
    });
    const { workout } = await response.json();
    console.log(workout);
    return workout;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const pingAPI = async () => {
  try {
    const api = `${getEndpoint()}/ping`;
    console.log(api);
    const response = await fetch(api);
    console.log(response);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    throw new Error("Ping API call failed: " + error.message);
  }
};

export { callGPT };

// ExerciseCard.js
import React, { useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import TimerWithButtons from "./TimerWithButtons";

const ExerciseCard = ({
  exerciseName,
  exerciseTime,
  reps,
  sets,
  description,
  background = "#fafafa",
  accent = "#f44336",
}) => {
  const [currentSet, setCurrentSet] = useState(0);

  const handleRepComplete = () => {
    if (currentSet < sets - 1) {
      setCurrentSet((prevSet) => prevSet + 1); // Increase the set count
    }
  };
  return (
    <Card style={{ marginBottom: "20px", minHeight: "600px", background }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          style={{ marginBottom: "10px" }}
        >
          {exerciseName}
        </Typography>
        <Typography
          variant="subtitle1"
          component="div"
          style={{ marginBottom: "10px" }}
        >
          Reps: {reps}
        </Typography>
        <Typography
          variant="subtitle1"
          component="div"
          style={{ marginBottom: "10px" }}
        >
          Sets: {currentSet + 1} / {sets}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          style={{ marginBottom: "10px" }}
        >
          {description}
        </Typography>
        <TimerWithButtons
          initialTime={exerciseTime}
          accent={accent}
          isLastRep={currentSet >= sets}
          currentSet={currentSet}
          sets={sets}
          onRepComplete={handleRepComplete} // Pass the handler to TimerWithButtons
        />
      </CardContent>
    </Card>
  );
};

export default ExerciseCard;

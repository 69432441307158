import { createTheme } from '@mui/material/styles';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Primary color
    },
    secondary: {
      main: '#dc004e', // Secondary color
    },
    background: {
      default: '#f4f6f8', // Default background color
      paper: '#ffffff',   // Background color for paper components
    },
    // Add other custom color definitions here
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // Add other typography settings here
  },
  spacing: 8, // Default spacing unit
  // Add other theme settings here
});

export default theme;

import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Card, CardContent } from "@mui/material";

const WorkoutSummary = ({ exercises }) => {
  return (
    <Card style={{ marginBottom: "20px", minHeight: "600px", background:"#fcfcfc" }}>
      <CardContent>
        <Typography>
          Here's today's workout!
          <br />
          For today's <b>warmup</b> you'll be doing{" "}
          {exercises.warmup.map((m) => m.name).join(", ")}
          <br />
          For today's <b>workout</b> you'll be doing{" "}
          {exercises.workout.map((m) => m.name).join(", ")}
          <br />
          For today's <b>cooldwon</b> you'll be doing{" "}
          {exercises.cooldown.map((m) => m.name).join(", ")}
        </Typography>
        <Typography display={"block"} variant="overline" marginTop={5}>⇠ Swipe to start the workout!</Typography>
      </CardContent>
    </Card>
  );
};

export default WorkoutSummary;

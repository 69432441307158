import React, { useState, useEffect } from "react";
import { IconButton, Box } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import CountdownTimer from "./CountdownTimer";
import "./TimerWithButtons.css"; // Import the CSS file

const TimerWithButtons = ({
  initialTime = 60,
  accent = "primary",
  onRepComplete,
  currentSet,
  sets,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [key, setKey] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (fadeOut) {
      const timer = setTimeout(() => {
        setFadeOut(false);
        setFadeIn(true);
        setKey((prevKey) => prevKey + 1); // Reset the timer with a new key to trigger the fade-in
        setTimeout(() => setFadeIn(false), 1000); // Remove fade-in effect after animation
      }, 1000); // Duration of fade-out animation
      return () => clearTimeout(timer);
    }
  }, [fadeOut]);

  const handleStartPause = () => {
    setIsActive(!isActive);
  };

  const handleReset = () => {
    setIsActive(false);
    setFadeOut(true); // Trigger fade-out when resetting
  };

  const handleComplete = () => {
    if (currentSet >= sets - 1) {
      // Is last rep
      console.log("LAST REP COMPLETED!");
    } else {
      // Trigger fade-out to fade in a new timer
      console.log("REP COMPLETED!");
      handleReset(); // Reset the timer
    }
    if (onRepComplete) {
      onRepComplete(); // Notify parent to increase the rep count
    }
  };

  return (
    <Box
      className={`timer-container ${fadeOut ? "fade-out" : ""} ${fadeIn ? "fade-in" : ""}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <CountdownTimer
        key={key}
        initialTime={initialTime}
        size={200}
        isActive={isActive}
        onComplete={handleComplete} // Pass the handleComplete function to CountdownTimer
      />
      <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
        <IconButton
          onClick={handleStartPause}
          style={{
            marginRight: "20px",
            width: 50,
            height: 50,
            backgroundColor: accent === "primary" ? "#3f51b5" : "#f50057",
            color: "#fff",
          }}
        >
          {isActive ? (
            <PauseIcon style={{ fontSize: 25 }} />
          ) : (
            <PlayArrowIcon style={{ fontSize: 25 }} />
          )}
        </IconButton>
        <IconButton
          onClick={handleReset}
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#f50057",
            color: "#fff",
          }}
        >
          <ReplayIcon style={{ fontSize: 25 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TimerWithButtons;

import React, { useState, useEffect } from "react";
import { Container, Tabs, Tab } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExerciseTabs from "./components/ExerciseTabs";
import WorkoutSelector from "./components/WorkoutSelector";
import SettingsPage from "./components/SettingsPage";
import { callGPT, pingAPI } from "./services/api";
import defaultExercises from "./data/excercisedata.json";

// Helper function to save data to localStorage with expiry
const saveToLocalStorage = (key, data, expiryHours) => {
  const expiryTime = new Date().getTime() + expiryHours * 60 * 60 * 1000;
  localStorage.setItem(key, JSON.stringify({ data, expiryTime }));
};

// Helper function to load data from localStorage
const loadFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  if (!item) return null;
  
  const { data, expiryTime } = JSON.parse(item);
  if (new Date().getTime() > expiryTime) {
    localStorage.removeItem(key);
    return null;
  }
  
  return data;
};

const App = () => {
  const [exercises, setExercises] = useState(() => loadFromLocalStorage("workoutData") || null);
  const [formData, setFormData] = useState({
    selectedMuscleGroups: [],
    preferredExercises: [],
    avoidedExercises: [],
    duration: "01:00",
    weight: "",
    goals: [],
  });
  const [tabIndex, setTabIndex] = useState(1);

  useEffect(() => {
    // Call the ping API when the component mounts
    const checkServer = async () => {
      try {
        await pingAPI();
        console.log("Server is up and running");
      } catch (error) {
        console.error("Error pinging the server:", error);
      }
    };
    checkServer();
  }, []);

  const handleChange = (field) => (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleFetchData = async () => {
    const workoutData = {
      ...formData,
      availableEquipment: [],
    };

    console.log(workoutData);

    try {
      setTabIndex(2); // Move to the second tab when data is fetched
      setExercises(null); // Reset exercises to show loading spinner
      const response = await callGPT({ ...workoutData });
      setExercises(response);
      saveToLocalStorage("workoutData", response, 12); // Save data with 12-hour expiry
    } catch (error) {
      console.error("Error fetching workout data:", error);
    }
  };

  const renderContent = () => {
    switch (tabIndex) {
      case 0:
        return <SettingsPage formData={formData} handleChange={handleChange} />;
      case 1:
        return (
          <WorkoutSelector
            formData={formData}
            handleChange={handleChange}
            handleFetchData={handleFetchData}
          />
        );
      case 2:
      default:
        return <ExerciseTabs exercises={exercises} />;
    }
  };

  return (
    <Container maxWidth="xs" sx={{ height: "100vh" }}>
      <Tabs
        variant="fullWidth"
        value={tabIndex}
        onChange={(e, newIndex) => setTabIndex(newIndex)}
        aria-label="Workout Tabs"
        sx={{ marginBottom: 2 }}
      >
        <Tab
          icon={<AccountCircleIcon fontSize="large" />}
          iconPosition="top"
          label="Profile"
          aria-label="Profile"
          sx={{ minWidth: "auto" }}
        />
        <Tab label="Create Workout" />
        <Tab label="View Workout" disabled={!exercises} />
      </Tabs>

      {renderContent()}
    </Container>
  );
};

export default App;

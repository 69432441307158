const createPrompt = ({ weight, goal, availableEquipment, duration, selectedMuscleGroups }) =>
    `You are an AI that's an expert in creating daily workout plans. Please create a workout plan based on the following user profile:

**Weight:** ${weight}

**Goals:** ${goal}

**Available equipment:**
-${availableEquipment.join('\n-')}

**Daily muscle target:**
-${selectedMuscleGroups.join('\n-')}

**Duration:**
Today's workout plan should take about ${duration} to complete.

The user doesn't like the following exercises:
- Romanian Deadlifts

The user has access to a gym

First, calculate the total duration of the workout plan in seconds.

Please return the result in JSON
duration: The duration per set (in seconds)
totalDuration: The duration * sets
ongoingDuration: The ongoing sum of the totalDuration per excercise.
restPeriod: The rest period between sets if applicable (in seconds)

SCHEMA:
Interface: Exercise [{name:string,duration:number,instruction:string,reps:number,sets:number,totalDuration:number,onGoingDuration:number,restPeriod:number}]
{warmup:[Exercise],workout:[Exercise],cooldown:[Exercise]}

Do not change the schema in any way.`;

export default createPrompt;
import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography
} from "@mui/material";

const SettingsPage = ({ formData, handleChange }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Settings
      </Typography>
      <TextField
        label="Weight"
        value={formData.weight}
        onChange={(e) => handleChange("weight")(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Goals"
        value={formData.goals}
        onChange={(e) => handleChange("goals")(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => alert("Settings saved!")}
      >
        Save Settings
      </Button>
    </Box>
  );
};

export default SettingsPage;

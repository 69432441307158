import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CircularSlider from "@fseehawer/react-circular-slider";

const durationValues = [];
for (let i = 15; i <= 120; i += 5) {
  const hours = Math.floor(i / 60);
  const minutes = i % 60;
  const formattedValue =
    hours > 0
      ? `${hours} hour${hours > 1 ? "s" : ""} ${
          minutes > 0 ? minutes + " mins" : ""
        }`
      : `${minutes} mins`;
  durationValues.push(formattedValue.trim());
}

const DurationPicker = ({ duration, handleDurationChange }) => {
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (value) => {
    setSliderValue(value);
    handleDurationChange(value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={2}
      mb={2}
    >
      <Typography variant="h6" component="div" style={{ marginBottom: "20px" }}>
        I want to work out for
      </Typography>
      <CircularSlider
        width={150}
        label="Duration"
        min={5}
        max={120}
        dataIndex={(sliderValue - 5) / 5}
        onChange={handleSliderChange}
        knobColor="#3f51b5"
        progressColorFrom="#3f51b5"
        progressColorTo="#3f51b5"
        trackColor="#ddd"
        data={durationValues}
        valueFontSize="1.0rem"
      />
    </Box>
  );
};

export default DurationPicker;

// CountdownTimer.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Nice!</div>;
  }

  return (
    <Box className="timer" sx={{ textAlign: "center" }}>
      <Typography variant="body" component="h2">
        {remainingTime}
      </Typography>
      <Typography>seconds</Typography>
    </Box>
  );
};

const CountdownTimer = ({ initialTime, isActive, onComplete }) => {
  return (
    <CountdownCircleTimer
      isPlaying={isActive}
      duration={initialTime}
      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
      colorsTime={[10, 6, 3, 0]}
      onComplete={onComplete} // Trigger onComplete callback on completion
    >
      {renderTime}
    </CountdownCircleTimer>
  );
};

export default CountdownTimer;

import React, { useState } from "react";
import { Container, Box, Tabs, Tab } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import ExerciseCard from "./ExerciseCard";
import WorkoutSummary from "./WorkoutSummary";
import LoadingBox from "./LoadingBox";

const ExerciseTabs = ({ exercises }) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!exercises) {
    return (<LoadingBox />);
  }
  // Prepare exercise data for tabs
  const allExercises = [
    {
      key: "summary",
      title: "Summary",
      content: <WorkoutSummary exercises={exercises} />,
    },
    ...exercises.warmup.map((exercise, index) => ({
      key: `warmup-${index}`,
      title: exercise.name,
      content: (
        <ExerciseCard
          key={index}
          background="#ECEFF1"
          accent="#B0BEC5"
          exerciseName={exercise.name}
          exerciseTime={exercise.duration}
          reps={exercise.reps}
          sets={exercise.sets}
          description={exercise.instruction}
        />
      ),
    })),
    ...exercises.workout.map((exercise, index) => ({
      key: `workout-${index}`,
      title: exercise.name,
      content: (
        <ExerciseCard
          key={index}
          background="#F1F8E9"
          accent="#4CAF50"
          exerciseName={exercise.name}
          exerciseTime={exercise.duration}
          reps={exercise.reps}
          sets={exercise.sets}
          description={exercise.instruction}
        />
      ),
    })),
    ...exercises.cooldown.map((exercise, index) => ({
      key: `cooldown-${index}`,
      title: exercise.name,
      content: (
        <ExerciseCard
          key={index}
          background="#E3F2FD"
          accent="#00BCD4"
          exerciseName={exercise.name}
          exerciseTime={exercise.duration}
          reps={exercise.reps}
          sets={exercise.sets}
          description={exercise.instruction}
        />
      ),
    })),
  ];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Container maxWidth="sm" sx={{ maxHeight: "100vh", position: "relative" }}>
      <SwipeableViews
        index={tabIndex}
        onChangeIndex={(index) => setTabIndex(index)}
        enableMouseEvents
        resistance
      >
        {allExercises.map((exercise) => (
          <Box key={exercise.key} sx={{ p: 2 }}>
            {exercise.content}
          </Box>
        ))}
      </SwipeableViews>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="Exercise Tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ position: "absolute", bottom: 0, width: "100%" }}
      >
        {allExercises.map((exercise) => (
          <Tab key={exercise.key} label={exercise.title} />
        ))}
      </Tabs>
    </Container>
  );
};

export default ExerciseTabs;

// LoadingBox.js
import React from "react";
import { Skeleton, Box, Typography } from "@mui/material";

const LoadingBox = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "700px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // Position relative to place text on top
      }}
    >
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={700}
        sx={{ borderRadius: 2 }}
      />
      <Typography
        variant="h6"
        component="div"
        sx={{
          position: "absolute",
          color: "text.primary",
          textAlign: "center",
          width: "100%",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        Creating your program...
      </Typography>
    </Box>
  );
};

export default LoadingBox;
